<template>
  <div class="contianer">
    <breadcrumb name="板块人口"></breadcrumb>
    <div class="innerbox">

      <block-where :rangeYear="rangeYear" :curAreaId="curAreaId" @cid-rangeyear="cidRangeyear"
        @change-areaid="changeAreaid"></block-where>
      <div class="scrollbox">
        <div v-if="nodata">
          <el-result icon="info" title="暂无数据" subTitle="当前城市数据不存在...">
          </el-result>
        </div>
        <div v-else class="report">
          <div class="tablebox">
            <div class="downbox">

              <div class="btnbox">
                <download-button type="excel" :params="{
                  goods_id: 4,
                  condition: { city_plate_id: city_plate_id, start_year: start_year, end_year: end_year }
                }" text="导出Excel"></download-button>
                <!-- <el-button type="primary" icon="el-icon-download" size="mini" @click.prevent="downloadExcel">导出Excel
              </el-button> -->


              </div>
              <div class="firstBox">
                <div class="left">
                  <div class="title"> {{  tableData.title  }} <span class="unit">({{  tableData.unit  }})</span></div>
                  <el-table ref="singleTable" @row-click="clickRow" class="table" :data="tableData.table" size="mini"
                    :highlight-current-row="true">
                    <el-table-column v-for="cell in tableData.header" :key="cell.prop" :prop="cell.prop"
                      :label="cell.label" :class-name="cell.prop" align="center">
                    </el-table-column>
                  </el-table>
                  <div class="remarks">{{  tableData.remarks  }}</div>
                  <time-source :data="{ time: tableData.uptime, source: tableData.source }"></time-source>

                </div>
                <div class="right">
                  <bar-2 :data="tableData" :showField="['cell2', 'cell3']"></bar-2>
                </div>
              </div>

              <div class="footerinfo">
              </div>
            </div>
            <div class="addtable">
              <!-- <table-1
            :tableData="addTableData"
            :cateid="cateid[1]"
            @download="downloadExcelByCateid"
            :hideRight="true"
          ></table-1> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Breadcrumb from '@/components/common/Breadcrumb.vue'
import BlockWhere from "@/components/cityreserch/BlockWhere.vue";
import Table1 from "@/components/tables/Table1.vue";
import TimeSource from "@/components/cityreserch/TimeSource.vue";
import Bar2 from '@/components/echarts/bar2.vue'

import DownloadButton from "@/components/down/DownloadButton.vue"
import {
  // cityYearByCateid,
  dataByBlock,
  rangeYearByBlock,
  RangeYearDownloadExcelByCateid,
  rangeYearByCateid,
  addTableDataByCateid,
  downloadExcelByCateid,
} from "@/api/econ";

export default {
  components: { BlockWhere, Breadcrumb, TimeSource, Table1, Bar2, DownloadButton },
  data() {
    return {
      cateid: [19],
      //   addTableData: {}, //附加表数据  table1 组建用的props
      nodata: false,
      rangeYear: [],
      curAreaId: 0,
      // citylist: [],
      tableData: {},
      valueObj: null,
      download_start_year: null,
      download_end_year: null,
      end_year: '',
      start_year: ''
    };
  },

  mounted() {
    this.getWhere();
  },
  computed: {
    city_plate_id: {
      get() {
        return this.$store.state.city_plate_id
      },
      set(v) {
        this.$store.dispatch('changeCityPlateId', v);
      }
    },
    shi: {
      get() {
        return this.$store.state.shi;
      },
      set(shi) {
        this.$store.dispatch("changeShi", shi);
      },
    },
    //当前选择的id
    block: {
      get() {
        return this.$store.state.block
      },
      set(v) {
        this.$store.commit('changeBlock', v)
      }
    }
  },
  watch: {
    block() {
      //   this.currentArea = this.cityList[0].id
      this.getWhere();
    },

    rangeYear: {
      handler(nv) {
        this.start_year = nv[0]
        this.end_year = nv[1]
  
      },
      deep: true,
      immediate:true
    }

  },
  methods: {
    //更改当前的区id
    changeAreaid(id) {
      console.log(this.rangeYear);
      this.getCurrentRange({ city_plate_id: id, cateid: this.cateid[0] });

    },

    getCurrentRange(params) {

      rangeYearByBlock(params).then((res) => {
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.rangeYear = res.data.rangeYear;
      });
    },



    //处理table1  emit的方法   根据cateid下载excel（ 不选择年份区间通用）
    downloadExcelByCateid(obj) {
      const params = {
        city_plate_id: this.block,
        cateid: obj.cateid,  //分类Id
        year: obj.year  //年份
      };

      downloadExcelByCateid(params).then((res) => {
        if (res.code === 1001) {
          this.$message("此文档不存在...");
        } else {
          this.download = res.data;
          window.location.href = this.download.fileUrl;

          // this.filesToRar([this.download],this.download.renameFileName)
        }
      });
    },
    //下载excel
    // downloadExcel() {
    //   const params = {
    //     city_plate_id: this.block,
    //     start_year: this.download_start_year || this.rangeYear[0],
    //     end_year: this.download_end_year || this.rangeYear[1]
    //   };

    //   downloadExcelByCateid(params).then((res) => {
    //     if (res.code === 1001) {
    //       this.$message("此文档不存在...");
    //     } else {
    //       this.download = res.data;
    //       window.location.href = this.download.fileUrl;
    //     }
    //   });
    // },

    //子组建返回的事件
    cidRangeyear(obj) {

      const params = {
        city_plate_id: obj.block,
        start_year: obj.startyear,
        end_year: obj.endyear,
      };

      this.start_year = obj.startyear
      this.end_year = obj.endyear
      
      this.download_start_year = obj.startyear
      this.download_end_year = obj.endyear

      this.getData(params);
    },


    //设置当前选中行
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    //点击行事件
    clickRow(row, column, event) {
      this.valueObj = row;
      //   this.getAddTableData();
      document.getElementsByClassName('addtable')[0].scrollIntoView({ behavior: "smooth" })
    },

    getWhere() {
      //参数 为通用的参数，当前城市id 和当前分类id
      const params = {
        city_plate_id: this.block
      };

      rangeYearByBlock(params).then((res) => {
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
          return false;
        }
        // this.citylist = res.data.citylist;
        this.rangeYear = res.data.rangeYear;
        // res.data.citylist.every((item) => {
        //   if (!item.disabled) {
        //     this.curAreaId = item.id;
        //     return false;
        //   }
        // });

        const params = {
          city_plate_id: this.block,
          start_year: this.rangeYear[0],
          end_year: this.rangeYear[1]
          // cateid: this.cateid[0],
        };

        this.getData(params);
      });
    },

    // code: 1, message: "成功", data: {title: "板块人口",…}}
    // code: 1
    // data: {title: "板块人口",…}
    // table: [{id: 1, cell1: 2022, cell2: 13}, {id: 2, cell1: 2021, cell2: 12}, {id: 3, cell1: 2020, cell2: 11},…]
    // 0: {id: 1, cell1: 2022, cell2: 13}
    // 1: {id: 2, cell1: 2021, cell2: 12}
    // 2: {id: 3, cell1: 2020, cell2: 11}
    // 3: {id: 4, cell1: 2019, cell2: 10}
    // 4: {id: 5, cell1: 2018, cell2: 9}
    // 5: {id: 6, cell1: 2017, cell2: 8}
    // 6: {id: 7, cell1: 2016, cell2: 7}
    // 7: {id: 8, cell1: 2015, cell2: 6}
    // 8: {id: 9, cell1: 2014, cell2: 5}
    // 9: {id: 10, cell1: 2013, cell2: 4}
    // 10: {id: 11, cell1: 2012, cell2: 3}
    // 11: {id: 12, cell1: 2011, cell2: 2}
    // 12: {id: 13, cell1: 2010, cell2: 1}


    getData(params) {
      dataByBlock(params).then((res) => {
        if (parseInt(res.code) === 1001) {
          this.nodata = true
        }
        this.tableData = res.data; //存储返回的数据
        this.valueObj = null;

        this.setCurrent(res.data.table[0]); //设置选中第一条

        // this.getAddTableData(); //获取table1的数据
      });
    },

    //获取table1的数据
    //根据返回的第一条数据，生成传入table1 组建的条件 （生成年份条件）
    // getAddTableData() {
    //   let valueObj = this.valueObj || this.tableData.table[0]; //获取点击的当前行的值 或者 是初始化数据的值
    //   console.log("获取当初始化以及点击当前行的数据", valueObj);
    //   //获取并处理组建需要的数据
    //   const params = {city_plate_id:this.curAreaId, year: valueObj.cell1, cateid: this.cateid[1] };
    //   addTableDataByCateid(params).then((res) => {
    //     const addData = res.data;
    //     this.addTableData = addData;
    //   });


    // },
  },
};
</script>

<style lang="scss" scoped>
.contianer {
  width: 100%;
}

.innerbox {
  width: 100%;
  height: auto;
}

.scrollbox {
  height: calc(100vh - 260px);
  overflow: scroll;
  width: 96%;
  margin: 0 auto;
}

.report {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .tablebox {
    width: 100%;

    .firstBox {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .left {
        .title {
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          margin-top:10px;
          margin-bottom:10px;
          font-weight: bold;
        }

        .unit {
          font-size: 14px;
        }
      }

      .right {}
    }

    .downbox {
      text-align: right;
      position: relative;
      top: 10px;
      left: -40px;
    }
  }

  .piebox {
    width: 40%;
    display: flex;
    flex-direction: column;

    .pie {
      min-height: 260px;
    }
  }
}

::v-deep .cell1,
::v-deep .cell4 {
  //   border-right: solid 2px #efefef;
}
</style>
